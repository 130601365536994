@font-face {
    font-family: 'AR One Sans';
    src: url('AROneSans-Medium.eot');
    src: local('AR One Sans Medium'), local('AROneSans-Medium'),
        url('AROneSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('AROneSans-Medium.woff2') format('woff2'),
        url('AROneSans-Medium.woff') format('woff'),
        url('AROneSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AR One Sans';
    src: url('AROneSans-SemiBold.eot');
    src: local('AR One Sans SemiBold'), local('AROneSans-SemiBold'),
        url('AROneSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('AROneSans-SemiBold.woff2') format('woff2'),
        url('AROneSans-SemiBold.woff') format('woff'),
        url('AROneSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AR One Sans';
    src: url('AROneSans-Regular.eot');
    src: local('AR One Sans Regular'), local('AROneSans-Regular'),
        url('AROneSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('AROneSans-Regular.woff2') format('woff2'),
        url('AROneSans-Regular.woff') format('woff'),
        url('AROneSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AR One Sans';
    src: url('AROneSans-Bold.eot');
    src: local('AR One Sans Bold'), local('AROneSans-Bold'),
        url('AROneSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('AROneSans-Bold.woff2') format('woff2'),
        url('AROneSans-Bold.woff') format('woff'),
        url('AROneSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

